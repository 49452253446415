import { IProps } from '../../types';

export function QrIcon({ className, onClick }: IProps) {
    return (
        <svg className={className} onClick={onClick} viewBox="0 0 308.667 308.667" width="24px" height="24px">
            <title>qr code</title>
            <rect x="38.073" y="38.073" width="47.592" height="47.592"/>
            <rect x="38.073" y="223.002" width="47.592" height="47.592"/>
            <path d="M0,163.852v21.076v13.598v110.141h123.739v-66.629h28.555v30.595v2.04v32.634h34.674v-28.555 h27.195v28.555h34.674v-32.634h25.156v32.634h34.674v-34.674h-32.634v-32.634h-34.674v32.634H218.58v-67.308h-31.612V176.77 h32.634v32.634h34.674v-21.756h19.717v14.278v18.357v16.317h34.674v-16.317v-18.357v-16.317h-21.756v-30.595h21.756v-31.275 v-3.399V0H184.928v0.228h-32.634v37.845h-28.555V0H0v109.461v14.278v20.396h21.756v19.717H0z M101.982,286.911H21.756v-80.226 h80.226V286.911z M154.333,209.404h29.578v30.595h-29.578V209.404z M154.333,144.135h27.533v30.595h-27.533V144.135z M273.993,152.974h-18.7v-10.878h-68.326v-18.357h87.025V152.974z M206.684,21.756h80.226v80.226h-80.226V21.756z M184.928,109.461h-30.595v-67.76h30.595V109.461z M152.294,142.096h-28.468v-30.595h28.468V142.096z M21.756,21.756h80.226 v80.226H21.756V21.756z M89.152,123.739v20.396h30.508v32.634h32.634v30.595h-28.555v-22.436H34.674v-8.159h19.717h2.04h32.634 v-34.674H56.43h-2.04H34.674v-18.357H89.152z"/>
            <rect x="223.002" y="38.073" width="47.592" height="47.592"/>
        </svg>
    );
}
